
import { Options, Vue } from "vue-class-component";
import ProjectSale from "../../service/ProjectSale";
import Toaster from "../../helpers/Toaster";

@Options({
  components: {},
})
export default class ProjectSales extends Vue {
  private lists = [];
  private ProjectSale;
  private branchList;
  private selectedCategory;
  private selectedCategoryObj;
  private productDialog = false;
  private statusDialog = false;
  private totalRecords = 0;
  private limit = 0;
  private home = { icon: "pi pi-home", to: "/" };
  private items = [
    { label: "Initialization", to: "initialization" },
    { label: "Projected sales" },
  ];
  private toast;

  private projectedSales = {
    id: 0,
    branch: {},
    year: {},
    weeksales: [
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
    ],
    status: "",
  };

  private dialogTitle;
  private dialogCallback;
  private submitted = false;

  getTenYears() {
    const years = [{}];
    const date = new Date();
    for (let y = date.getFullYear(); y <= date.getFullYear() + 10; y++) {
      years.push({ key: y });
    }
    years.splice(0, 1);
    return years;
  }

  //CALLING WHEN PAGINATION BUTTON CLICKS
  onPage(event) {
    this.loadList(event.first);
  }

  //DEFAULT METHOD OF TYPE SCRIPT
  //CALLING WHENEVER COMPONENT LOADS
  created() {
    this.ProjectSale = new ProjectSale();
    this.toast = new Toaster();
  }

  //OPEN DIALOG TO ADD NEW ITEM
  openDialog() {
    this.projectedSales = {
      id: 0,
      branch: {},
      year: {},
      weeksales: [
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
      ],
      status: "",
    };

    this.submitted = false;
    this.dialogTitle = " Add Week Projected Sales for the year";
    this.productDialog = true;
  }

  //CLOSE THE ITEM DAILOG BOX
  hideDialog() {
    this.productDialog = false;
    this.submitted = false;
  }

  //ADD OR UPDATE THE ITEM VIA HTTP
  saveItem(e) {
    e.preventDefault();
    this.submitted = true;
    if (this.projectedSales.weeksales.length >= 53) {
      if (this.projectedSales.id != 0) {
        this.ProjectSale.updateItem(this.projectedSales).then((res) => {
          this.loadList(0);
          //SHOW NOTIFICATION
          this.toast.handleResponse(res);
        });
      } else {
        this.ProjectSale.saveItem(this.projectedSales).then((res) => {
          this.loadList(0);
          //SHOW NOTIFICATION
          this.toast.handleResponse(res);
        });
      }

      this.productDialog = false;
      this.projectedSales = {
        id: 0,
        branch: "",
        year: "",
        weeksales: [],
        status: "",
      };
    }
  }

  //OPEN DIALOG BOX FOR CONFIRMATION
  confirmDelete(data) {
    this.projectedSales.id = data.yearly_planned_id;
    this.statusDialog = true;
  }

  //CHANGE THE STATUS AND SEND HTTP TO SERVER
  deleted() {
    this.statusDialog = false;
    this.ProjectSale.deleted(this.projectedSales).then((res) => {
      this.loadList(0);
      //SHOW NOTIFICATION
      this.toast.handleResponse(res);
    });
  }

  //CALLNING AFTER CONSTRUCTOR GET CALLED
  mounted() {
    this.loadList(0);
  }

  //FETCH THE DATA FROM SERVER
  loadList(page) {
    this.ProjectSale.getItems(page).then((data) => {
      this.lists = data.projected_list;
      this.branchList = data.branches_list;
    });
  }
}
