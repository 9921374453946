
import { Options, Vue } from "vue-class-component";
import ReceiptPrinter from "../../service/ReceiptPrinter";
import Toaster from "../../helpers/Toaster";

@Options({
  components: {},
})
export default class ReceiptPrinters extends Vue {
  private imagePath = "";
  private keyword = "";
  private checkPagination = true;
  private lists = [];
  private receiptPrinter;
  private productDialog = false;
  private statusDialog = false;
  private loading = false;
  private totalRecords = 0;
  private limit = 0;
  private home = { icon: "pi pi-home", to: "/" };
  private items = [
    { label: "Initialization", to: "initialization" },
    { label: "Printer Receipt" },
  ];
  private toast;

  private product = {
    id: 0,
    receiptHeading: "",
    receiptContent: "",
    priority: 2,
    status: "",
  };

  private dialogTitle;
  private dialogCallback;
  private submitted = false;

  //CALLING WHEN PAGINATION BUTTON CLICKS
  onPage(event) {
    this.loadList(event.first);
  }

  //DEFAULT METHOD OF TYPE SCRIPT
  //CALLING WHENEVER COMPONENT LOADS
  created() {
    this.receiptPrinter = new ReceiptPrinter();
    this.toast = new Toaster();
    this.imagePath = this.receiptPrinter.getBaseURL() + "uploads/services/";
  }

  //OPEN DIALOG TO ADD NEW ITEM
  openDialog() {
    this.product = {
      id: 0,
      receiptHeading: "",
      receiptContent: "",
      priority: 2,
      status: "",
    };

    this.submitted = false;
    this.dialogTitle = "Add New Service";
    this.productDialog = true;
  }

  //CLOSE THE ITEM DAILOG BOX
  hideDialog() {
    this.productDialog = false;
    this.submitted = false;
  }

  //ADD OR UPDATE THE ITEM VIA HTTP
  saveItem(e) {
    e.preventDefault();

    this.submitted = true;
    if (this.product.receiptHeading.trim()) {
      if (this.product.id != 0) {
        this.receiptPrinter.updateItem(this.product).then((res) => {
          this.loadList(0);
          //SHOW NOTIFICATION
          this.toast.handleResponse(res);
        });
      } else {
        this.receiptPrinter.saveItem(this.product).then((res) => {
          this.loadList(0);
          //SHOW NOTIFICATION
          this.toast.handleResponse(res);
        });
      }

      this.productDialog = false;
      this.product = {
        id: 0,
        receiptHeading: "",
        receiptContent: "",
        priority: 2,
        status: "",
      };
    }
  }

  //OPEN DIALOG BOX TO EDIT
  editIem(data) {
    this.submitted = false;
    this.dialogTitle = "Update Printer Receipt";
    this.productDialog = true;

    this.receiptPrinter.getItem(data).then((res) => {
      if (res) {
        this.product = {
          id: res.receipt_id,
          receiptHeading: res.receipt_heading,
          receiptContent: res.receipt_content,
          priority: res.priority,
          status: res.status,
        };
      }
    });
  }

  //OPEN DIALOG BOX FOR CONFIRMATION
  confirmDelete(data) {
    this.product.id = data.receipt_id;

    this.statusDialog = true;
  }

  //DELETE THE iTEM
  deleted() {
    this.statusDialog = false;
    this.receiptPrinter.deleted(this.product).then((res) => {
      this.loadList(0);
      //SHOW NOTIFICATION
      this.toast.handleResponse(res);
    });
  }

  //CALLNING AFTER CONSTRUCTOR GET CALLED
  mounted() {
    this.loadList(0);
  }

  //FETCH THE DATA FROM SERVER
  loadList(page) {
    this.loading = true;
    this.receiptPrinter.getItems(page).then((data) => {
      this.lists = data.print_record;
      this.totalRecords = data.total_printer_count;
      this.limit = data.limit_per_page;
      this.loading = false;
    });
  }

  loadSearchData() {
    this.submitted = true;
    if (this.keyword) {
      this.receiptPrinter
        .getSearchedPrinterReceipt(this.keyword)
        .then((data) => {
          this.lists = data.print_record;
          this.checkPagination = data.pagination;
        });
    }
  }
}
