<template>
  <section>
    <div class="app-container">
      <Toolbar>
        <template v-slot:left>
          <Breadcrumb
            :home="home"
            :model="items"
            class="p-menuitem-text p-p-1"
          />
        </template>

        <template v-slot:right>
          <div class="p-inputgroup">
            <InputText
              v-model.trim="keyword"
              required="true"
              :class="{ 'p-invalid': submitted && !keyword }"
              placeholder="Search"
            />
            <Button
              icon="pi pi-search "
              class="p-button-primary p-mr-1"
              @click="loadSearchData"
            />
          </div>
          <div class="p-mx-2">
            <Button
              icon="pi pi-plus"
              class="p-button-success"
              @click="openDialog"
            />
          </div>
        </template>
      </Toolbar>
      <div class="p-mt-2">
        <DataTable
          :value="lists"
          :lazy="true"
          :paginator="checkPagination"
          :rows="limit"
          :totalRecords="totalRecords"
          :scrollable="true"
          @page="onPage($event)"
          class="p-datatable-sm p-datatable-striped p-datatable-gridlines"
        >
          <template #empty>
            <div class="p-text-center p-p-3">No records found</div>
          </template>
          <Column field="region_id" header="Region Id"></Column>
          <Column field="region_code" header="Region Code"></Column>
          <Column field="province_name" header="Province"></Column>
          <Column field="region_name" header="Region"></Column>
          <Column field="country" header="Country"></Column>
          <Column field="created_date" header="Date"></Column>
          <Column field="status" header="Status"></Column>
          <Column :exportable="false" header="Action">
            <template #body="slotProps">
              <Button
                icon="pi pi-pencil"
                class="p-button-rounded p-button-primary p-mr-2"
                @click="editIem(slotProps.data)"
              />
              <Button
                v-if="slotProps.data.status == 'Active'"
                icon="pi pi-eye-slash"
                class="p-button-rounded p-button-danger"
                @click="confirmChangeStatus(slotProps.data, 'Inactive')"
              />
              <Button
                v-else-if="slotProps.data.status == 'Inactive'"
                icon="pi pi-eye"
                class="p-button-rounded p-button-warning"
                @click="confirmChangeStatus(slotProps.data, 'Active')"
              />
            </template>
          </Column>
        </DataTable>
      </div>

      <Dialog
        v-model:visible="productDialog"
        :style="{ width: '50vw' }"
        :maximizable="true"
        position="top"
        class="p-fluid"
      >
        <template #header>
          <h4 class="p-dialog-titlebar p-dialog-titlebar-icon">
            {{ dialogTitle }}
          </h4>
        </template>
        <div class="p-field">
          <label for="province">Province/Territories/States:</label>
          <Dropdown
            v-model="product.province"
            :options="provinces"
            optionLabel="key"
            :filter="true"
          />
        </div>
        <div class="p-field">
          <label for="region">Region Name:</label>
          <InputText
            id="region"
            v-model.trim="product.region"
            required="true"
            placeholder="Enter region name"
            autofocus
            :class="{ 'p-invalid': submitted && !product.name }"
          />
          <small class="p-invalid" v-if="submitted && !product.region"
            >Region Name is required.</small
          >
        </div>
        <div class="p-field">
          <label for="country">Country:</label>
          <Dropdown
            v-model="product.country"
            :options="country"
            optionLabel="key"
          />
        </div>
        <div class="p-field">
          <label for="regioncode">Region Code:</label>
          <InputText
            id="region_code"
            v-model.trim="product.regioncode"
            placeholder="Enter region code"
          />
        </div>
        <template #footer>
          <Button
            type="submit"
            label="Save"
            icon="pi pi-check"
            class="p-button-primary"
            @click="saveItem"
          />
        </template>
      </Dialog>

      <Dialog
        v-model:visible="statusDialog"
        :style="{ width: '450px' }"
        header="Confirm"
      >
        <div class="confirmation-content">
          <i
            class="pi pi-exclamation-triangle p-mr-3"
            style="font-size: 2rem"
          />
          <span v-if="product"
            >Change the status of <b>{{ product.region }}</b> to
            {{ productStatus }}?</span
          >
        </div>
        <template #footer>
          <Button
            label="No"
            icon="pi pi-times"
            class="p-button-text"
            @click="statusDialog = false"
          />
          <Button
            label="Yes"
            icon="pi pi-check"
            class="p-button-text"
            @click="changeStatus"
          />
        </template>
      </Dialog>
    </div>
  </section>
</template>
<script lang="ts">
import { Options, Vue } from "vue-class-component";
import Region from "../../service/Region";
import Toaster from "../../helpers/Toaster";

@Options({
  components: {},
})
export default class Regions extends Vue {
  private imagePath = "";
  private lists = [];
  private regions;
  private productStatus = "";
  private keyword = "";
  private productDialog = false;
  private checkPagination = true;
  private statusDialog = false;
  private totalRecords = 0;
  private limit = 0;
  private home = { icon: "pi pi-home", to: "/" };
  private items = [
    { label: "Initialization", to: "initialization" },
    { label: "Regions" },
  ];
  private toast;

  private product = {
    id: 0,
    province: "",
    region: "",
    country: "",
    regioncode: "",
    status: "",
  };

  private country = [{ key: "Canada" }, { key: "Usa" }];
  private provinces = [
    { key: "Nunavut" },
    { key: "Quebec" },
    { key: "Northwest Territories" },
    { key: "Ontario" },
    { key: "British Columbia" },
    { key: "Alberta" },
    { key: "Saskatchewan" },
    { key: "Yukon" },
    { key: "Newfoundland and Labrado" },
    { key: "New Brunswick" },
    { key: "Nova Scotia" },
    { key: "Alabama" },
    { key: "Alaska" },
    { key: "Arizona" },
    { key: "Arkansas" },
    { key: "California" },
    { key: "Colorado" },
    { key: "Connecticut" },
    { key: "Delaware" },
    { key: "Florida" },
    { key: "Georgia" },
    { key: "Hawaii" },
    { key: "Idaho" },
    { key: "Illinois" },
    { key: "Indiana" },
    { key: "Iowa" },
    { key: "Kansas" },
    { key: "Kentucky" },
    { key: "Louisiana" },
    { key: "Maine" },
    { key: "Maryland" },
    { key: "Massachusetts" },
    { key: "Michigan" },
    { key: "Minnesota" },
    { key: "Mississippi" },
    { key: "Missouri" },
    { key: "Montana" },
    { key: "Nebraska" },
    { key: "Nevada" },
    { key: "New Hampshire" },
    { key: "New Jersey" },
    { key: "New Mexic" },
    { key: "New York" },
    { key: "North Carolina" },
    { key: "North Dakota" },
    { key: "Ohio" },
    { key: "Oklahoma" },
    { key: "Oregon" },
    { key: "Pennsylvania" },
    { key: "Rhode Island" },
    { key: "South Carolina" },
    { key: "South Dakota" },
    { key: "Tennessee" },
    { key: "Texas" },
    { key: "Utah" },
    { key: "Vermont" },
    { key: "Virginia" },
    { key: "Washington" },
    { key: "West Virginia" },
    { key: "Wisconsin" },
    { key: "Wyoming" },
  ];

  private selectedProvince;
  private selectedProvinceObj;

  private selectedCountry;
  private selectedCountryObj;

  private dialogTitle;
  private dialogCallback;
  private submitted = false;

  //CALLING WHEN PAGINATION BUTTON CLICKS
  onPage(event) {
    this.loadList(event.first);
  }

  //DEFAULT METHOD OF TYPE SCRIPT
  //CALLING WHENEVER COMPONENT LOADS
  created() {
    this.regions = new Region();
    this.toast = new Toaster();
    this.imagePath = this.regions.getBaseURL() + "uploads/services/";
  }

  //OPEN DIALOG TO ADD NEW ITEM
  openDialog() {
    this.product = {
      id: 0,
      province: "",
      region: "",
      country: "",
      regioncode: "",
      status: "",
    };

    this.submitted = false;
    this.dialogTitle = "Add New Region";
    this.productDialog = true;
  }

  //CLOSE THE ITEM DAILOG BOX
  hideDialog() {
    this.productDialog = false;
    this.submitted = false;
  }

  //ADD OR UPDATE THE ITEM VIA HTTP
  saveItem(e) {
    e.preventDefault();

    this.submitted = true;
    if (this.product.region.trim()) {
      if (this.product.id != 0) {
        this.regions.updateItem(this.product).then((res) => {
          this.loadList(0);
          //SHOW NOTIFICATION
          this.toast.handleResponse(res);
        });
      } else {
        this.regions.saveItem(this.product).then((res) => {
          this.loadList(0);
          //SHOW NOTIFICATION
          this.toast.handleResponse(res);
        });
      }

      this.productDialog = false;
      this.product = {
        id: 0,
        province: "",
        region: "",
        country: "",
        regioncode: "",
        status: "",
      };
    }
  }

  //SET DROPDOWN DATA IN EDIT FORM
  setDropDownData() {
    this.provinces.filter((elem) => {
      if (elem.key == this.selectedProvince) {
        this.selectedProvinceObj = elem;
      }
    });

    this.country.filter((elem) => {
      if (elem.key == this.selectedCountry) {
        this.selectedCountryObj = elem;
      }
    });
  }

  //OPEN DIALOG BOX TO EDIT
  editIem(data) {
    this.submitted = false;
    this.dialogTitle = "Update Region";
    this.productDialog = true;

    this.regions.getItem(data).then((res) => {
      if (res.length > 0) {
        this.selectedCountry = res[0].country;
        this.selectedProvince = res[0].province_name;
        this.setDropDownData();

        this.product = {
          id: res[0].region_id,
          province: this.selectedProvinceObj,
          region: res[0].region_name,
          country: this.selectedCountryObj,
          regioncode: res[0].region_code,
          status: res[0].status,
        };
      }
    });
  }

  //OPEN DIALOG BOX FOR CONFIRMATION
  confirmChangeStatus(data, status) {
    this.product = {
      id: data.region_id,
      province: "",
      region: data.region_name,
      country: "",
      regioncode: "",
      status: data.status,
    };
    this.productStatus = status;
    this.statusDialog = true;
  }

  //CHANGE THE STATUS AND SEND HTTP TO SERVER
  changeStatus() {
    this.statusDialog = false;
    this.product.status = this.productStatus;
    this.regions.changeStatus(this.product).then((res) => {
      this.loadList(0);
      //SHOW NOTIFICATION
      this.toast.handleResponse(res);
    });
  }

  //CALLNING AFTER CONSTRUCTOR GET CALLED
  mounted() {
    this.loadList(0);
  }

  //FETCH THE DATA FROM SERVER
  loadList(page) {
    this.regions.getItems(page).then((data) => {
      this.lists = data.regions_records;
      this.totalRecords = data.total_region_count;
      this.limit = data.limit_per_page;
    });
  }

  loadSearchData() {
    this.submitted = true;
    if (this.keyword) {
      this.regions.getSearchedRegions(this.keyword).then((data) => {
        this.lists = data.regions_records;
        this.checkPagination = data.pagination;
      });
    }
  }
}
</script>

<style scoped>
.product-image {
  width: 30px;
  height: auto;
}
</style>
