
import { Options, Vue } from "vue-class-component";
import Region from "../../service/Region";
import Toaster from "../../helpers/Toaster";

@Options({
  components: {},
})
export default class Regions extends Vue {
  private imagePath = "";
  private lists = [];
  private regions;
  private productStatus = "";
  private keyword = "";
  private productDialog = false;
  private checkPagination = true;
  private statusDialog = false;
  private totalRecords = 0;
  private limit = 0;
  private home = { icon: "pi pi-home", to: "/" };
  private items = [
    { label: "Initialization", to: "initialization" },
    { label: "Regions" },
  ];
  private toast;

  private product = {
    id: 0,
    province: "",
    region: "",
    country: "",
    regioncode: "",
    status: "",
  };

  private country = [{ key: "Canada" }, { key: "Usa" }];
  private provinces = [
    { key: "Nunavut" },
    { key: "Quebec" },
    { key: "Northwest Territories" },
    { key: "Ontario" },
    { key: "British Columbia" },
    { key: "Alberta" },
    { key: "Saskatchewan" },
    { key: "Yukon" },
    { key: "Newfoundland and Labrado" },
    { key: "New Brunswick" },
    { key: "Nova Scotia" },
    { key: "Alabama" },
    { key: "Alaska" },
    { key: "Arizona" },
    { key: "Arkansas" },
    { key: "California" },
    { key: "Colorado" },
    { key: "Connecticut" },
    { key: "Delaware" },
    { key: "Florida" },
    { key: "Georgia" },
    { key: "Hawaii" },
    { key: "Idaho" },
    { key: "Illinois" },
    { key: "Indiana" },
    { key: "Iowa" },
    { key: "Kansas" },
    { key: "Kentucky" },
    { key: "Louisiana" },
    { key: "Maine" },
    { key: "Maryland" },
    { key: "Massachusetts" },
    { key: "Michigan" },
    { key: "Minnesota" },
    { key: "Mississippi" },
    { key: "Missouri" },
    { key: "Montana" },
    { key: "Nebraska" },
    { key: "Nevada" },
    { key: "New Hampshire" },
    { key: "New Jersey" },
    { key: "New Mexic" },
    { key: "New York" },
    { key: "North Carolina" },
    { key: "North Dakota" },
    { key: "Ohio" },
    { key: "Oklahoma" },
    { key: "Oregon" },
    { key: "Pennsylvania" },
    { key: "Rhode Island" },
    { key: "South Carolina" },
    { key: "South Dakota" },
    { key: "Tennessee" },
    { key: "Texas" },
    { key: "Utah" },
    { key: "Vermont" },
    { key: "Virginia" },
    { key: "Washington" },
    { key: "West Virginia" },
    { key: "Wisconsin" },
    { key: "Wyoming" },
  ];

  private selectedProvince;
  private selectedProvinceObj;

  private selectedCountry;
  private selectedCountryObj;

  private dialogTitle;
  private dialogCallback;
  private submitted = false;

  //CALLING WHEN PAGINATION BUTTON CLICKS
  onPage(event) {
    this.loadList(event.first);
  }

  //DEFAULT METHOD OF TYPE SCRIPT
  //CALLING WHENEVER COMPONENT LOADS
  created() {
    this.regions = new Region();
    this.toast = new Toaster();
    this.imagePath = this.regions.getBaseURL() + "uploads/services/";
  }

  //OPEN DIALOG TO ADD NEW ITEM
  openDialog() {
    this.product = {
      id: 0,
      province: "",
      region: "",
      country: "",
      regioncode: "",
      status: "",
    };

    this.submitted = false;
    this.dialogTitle = "Add New Region";
    this.productDialog = true;
  }

  //CLOSE THE ITEM DAILOG BOX
  hideDialog() {
    this.productDialog = false;
    this.submitted = false;
  }

  //ADD OR UPDATE THE ITEM VIA HTTP
  saveItem(e) {
    e.preventDefault();

    this.submitted = true;
    if (this.product.region.trim()) {
      if (this.product.id != 0) {
        this.regions.updateItem(this.product).then((res) => {
          this.loadList(0);
          //SHOW NOTIFICATION
          this.toast.handleResponse(res);
        });
      } else {
        this.regions.saveItem(this.product).then((res) => {
          this.loadList(0);
          //SHOW NOTIFICATION
          this.toast.handleResponse(res);
        });
      }

      this.productDialog = false;
      this.product = {
        id: 0,
        province: "",
        region: "",
        country: "",
        regioncode: "",
        status: "",
      };
    }
  }

  //SET DROPDOWN DATA IN EDIT FORM
  setDropDownData() {
    this.provinces.filter((elem) => {
      if (elem.key == this.selectedProvince) {
        this.selectedProvinceObj = elem;
      }
    });

    this.country.filter((elem) => {
      if (elem.key == this.selectedCountry) {
        this.selectedCountryObj = elem;
      }
    });
  }

  //OPEN DIALOG BOX TO EDIT
  editIem(data) {
    this.submitted = false;
    this.dialogTitle = "Update Region";
    this.productDialog = true;

    this.regions.getItem(data).then((res) => {
      if (res.length > 0) {
        this.selectedCountry = res[0].country;
        this.selectedProvince = res[0].province_name;
        this.setDropDownData();

        this.product = {
          id: res[0].region_id,
          province: this.selectedProvinceObj,
          region: res[0].region_name,
          country: this.selectedCountryObj,
          regioncode: res[0].region_code,
          status: res[0].status,
        };
      }
    });
  }

  //OPEN DIALOG BOX FOR CONFIRMATION
  confirmChangeStatus(data, status) {
    this.product = {
      id: data.region_id,
      province: "",
      region: data.region_name,
      country: "",
      regioncode: "",
      status: data.status,
    };
    this.productStatus = status;
    this.statusDialog = true;
  }

  //CHANGE THE STATUS AND SEND HTTP TO SERVER
  changeStatus() {
    this.statusDialog = false;
    this.product.status = this.productStatus;
    this.regions.changeStatus(this.product).then((res) => {
      this.loadList(0);
      //SHOW NOTIFICATION
      this.toast.handleResponse(res);
    });
  }

  //CALLNING AFTER CONSTRUCTOR GET CALLED
  mounted() {
    this.loadList(0);
  }

  //FETCH THE DATA FROM SERVER
  loadList(page) {
    this.regions.getItems(page).then((data) => {
      this.lists = data.regions_records;
      this.totalRecords = data.total_region_count;
      this.limit = data.limit_per_page;
    });
  }

  loadSearchData() {
    this.submitted = true;
    if (this.keyword) {
      this.regions.getSearchedRegions(this.keyword).then((data) => {
        this.lists = data.regions_records;
        this.checkPagination = data.pagination;
      });
    }
  }
}
