
import { Options, Vue } from "vue-class-component";
import Toaster from "../../helpers/Toaster";
import PriceListService from "../../service/PriceListService";
import { camelCase } from "lodash";
@Options({
  components: {},
})
export default class PriceList extends Vue {
  private viewTabs = "filter-price-list";
  private viewSearchResult = "";
  private home = { icon: "pi pi-home", to: "/" };
  private items = [
    { label: "Initialization", to: "initialization" },
    { label: " Price List", to: "/store/price-list" },
  ];

  private toast;
  private priceTxn;
  private submitted = false;
  private allItemsCheckbox = "";
  private allConditionCheckbox = "";
  private addItemDailog = false;
  private itemPriceOption = false;
  private serviceList = [];
  private departmentList = [];
  private scheduleList = [];
  private itemList = [];
  private itemListPopup = [];
  private popupList = [];
  private optApply = ['all'];
  private price = 0;
  private navigatetor = "";

  private percentageList = [
    {
      name: "Percentage Plus",
      value: "percentage_plus",
    },
    {
      name: "Percentage Minus",
      value: "percentage_minus",
    },
  ];

  private seletectedSchedule = {
    id: "",
    scheduleName: "",
  };

  private seletectedScheduleFrom = {
    id: "",
    scheduleName: "",
  };

  private seletectedScheduleTo = {
    id: "",
    scheduleName: "",
  };

  private seletectedService = {
    id: "",
    name: "",
  };

  private seletectedDepart = {
    id: "",
    sector: "",
  };

  private selecteditem = {
    id: "",
    itemName: "",
  };

  private selectedPrecentage = {
    name: "",
    value: "",
  };

  private seletectedPopUp = {
    itemSpId: "",
  };

  // private priceList = {
  //   itemPrice: [0],
  //   condPrice: "",
  //   department: "",
  //   itemStatus: [''],
  //   popupStatus:"Active"
  // };

  private percentageValue = 0;

  searchPriceList() {
    this.viewSearchResult = 'price-list-search-result';
    this.priceTxn
      .searchPriceList(
        this.seletectedService.id,
        this.seletectedDepart.id,
        this.seletectedSchedule.id,
      )
      .then((res) => {
        const d = this.camelizeKeys(res);
        this.navigatetor = d.schName +' > '+ d.serviceName +' > '+ d.departmentName;
        this.popupList = d.popus
        this.itemList = d.itemList;
      });
  }

  created() {
    this.priceTxn = new PriceListService();
    this.toast = new Toaster();
  }

  mounted() {
    this.priceTxn.getPriceItemList().then((data) => {
      const d = this.camelizeKeys(data);
      this.serviceList = d.serviceList;
      this.departmentList = d.departmentList;
      this.scheduleList = d.scheduleList;
    });
  }

  openItemDialog() {
    this.addItemDailog = true;

    //LOAD THE ITEMS LOADED BY DEFAULT
    this.fetchItems();
  }

  //SWITCH TABS
  activePanel(value) {
    this.viewTabs = value;
  }

  camelizeKeys = (obj) => {
    if (Array.isArray(obj)) {
      return obj.map((v) => this.camelizeKeys(v));
    } else if (obj !== null && obj.constructor === Object) {
      return Object.keys(obj).reduce(
        (result, key) => ({
          ...result,
          [camelCase(key)]: this.camelizeKeys(obj[key]),
        }),
        {}
      );
    }
    return obj;
  };

  fetchItems() {
    if (this.seletectedService.id != "" && this.seletectedDepart.id != "") {
      this.priceTxn
        .getItemList(this.seletectedService.id, this.seletectedDepart.id)
        .then((data) => {
          const item = this.camelizeKeys(data);
          this.itemListPopup = item;
        });
    }
  }

  saveItem() {
    this.priceTxn
      .saveItemPrice(
        this.seletectedService.id,
        this.seletectedDepart.id,
        this.seletectedSchedule.id,
        this.selecteditem.id,
        this.price
      )
      .then((res) => {
        this.toast.handleResponse(res);
        this.addItemDailog = false;

        //REFRESH THE PRICE LIST
        this.searchPriceList();
      });
  }

  openOptionSettings() {
    this.itemPriceOption = true;
  }

  applyOption() {
    if (this.selectedPrecentage.value == "" || this.percentageValue == 0) {
      this.toast.showWarning(
        "Please enter the value greater then zero or choose the apply percentage option"
      );
    } else {
      this.priceTxn
        .applyOption(
          this.seletectedService.id,
          this.seletectedDepart.id,
          this.seletectedSchedule.id,
          this.selectedPrecentage.value,
          this.percentageValue
        )
        .then((res) => {
          this.toast.handleResponse(res);
          this.itemPriceOption = false;
        });
    }
  }

  formatAmount(value) {
    value = Number(value);

    let balance = 0;

    if (value != "") {
      balance = value.toFixed(2);
    }
    return balance;
  }

  addPopup()
  {
    this.priceTxn
      .addPopup(
       this.seletectedSchedule.id,
       this.seletectedPopUp.itemSpId
      )
      .then((res) => {
        this.toast.handleResponse(res);
        this.searchPriceList();
    });
  }

  copyPriceList() {
    this.priceTxn
    .copyPriceList(
      this.seletectedScheduleFrom.id,
      this.seletectedScheduleTo.id,
      this.optApply
    )
    .then((res) => {
      this.toast.handleResponse(res);
    });
  }

  //CLEAR DATA WHEN TOGGLING TABS IN DIALOG BOX
  clearObject() {
    this.optApply = [];
  }

  updatePricelist()
  {
    this.priceTxn
      .updatePriceList(
        this.seletectedSchedule.id,
        this.itemList
      )
      .then((res) => {
        this.toast.handleResponse(res);
        if(res.alert == 'info')
        {
          this.searchPriceList();
        }
      });
  }
}
