<template>
  <section>
    <div class="app-container">
      <Toolbar>
        <template v-slot:left>
          <Breadcrumb
            :home="home"
            :model="items"
            class="p-menuitem-text p-p-1"
          />
        </template>

        <template v-slot:right>
          <Button
            @click="openItemDialog()"
            class="p-button-success p-mr-1"
            label="Add Item & Price"
            icon="pi pi-plus-circle"
          />
          <Button
            @click="openOptionSettings()"
            class="p-button-primary p-mr-1"
            label="Price List Options"
            icon="pi pi-cog"
          />
          <Button
            @click="activePanel('filter-price-list')"
            class="p-button-warning p-mr-1"
            label="Filter Price List"
            icon="pi pi-filter"
          />
          <Button
            @click="activePanel('copy-price-list')"
            class="p-button-info"
            label="Copy Price List"
            icon="pi pi-clone"
          />
        </template>
      </Toolbar>
      <div class="p-2"> 
        <div class="filter-price-list" v-if="viewTabs == 'filter-price-list'">
          <h5 class="p-mt-3"><i class="pi pi-filter"></i> Filter Price List</h5>
          <div class="p-fluid p-formgrid p-grid">
            <div class="p-field p-col-3">
              <Dropdown v-model="seletectedSchedule" placeholder="Select Schedule" :options="scheduleList" optionLabel="scheduleName" :filter="true" />
            </div>
            <div class="p-field p-col-3">
              <Dropdown v-model="seletectedService" placeholder="Select Service" :options="serviceList" optionLabel="name" :filter="true"  />
            </div>
            <div class="p-field p-col-3">
              <Dropdown v-model="seletectedDepart" placeholder="Select Department" :options="departmentList" optionLabel="sector" :filter="true"  />
            </div>
            <div class="p-field p-col-3">
              <Button
                type="submit"
                class="p-button-primary"
                label="Search Price List"
                icon="pi pi-search"
                @click="searchPriceList"
              />
            </div>
          </div>
          <div class="price-list-search-result" v-if="viewSearchResult == 'price-list-search-result'">
            <div class="p-grid">
              <div class="p-col-12">
                <h5 class="mt-3">
                  <i class="pi pi-plus-circle"></i> Add Popup Item to List
                </h5>
                <div class="p-fluid p-formgrid p-grid">
                  <div class="p-field p-col-9">
                    <Dropdown :options="popupList" v-model="seletectedPopUp"   optionLabel="itemSpName" placeholder="Select Popup" :filter="true" >
                      <template #value="slotProps">
                          <span>Item Name {{slotProps.value.itemName}} | Popup Name {{slotProps.value.itemSpName}}</span>
                      </template>
                      <template #option="slotProps">
                          <span>Item Name {{slotProps.option.itemName}}  | Popup Name {{slotProps.option.itemSpName}}</span>
                      </template>
                    </Dropdown>
                  </div>
                  <div class="p-field p-col-3">
                    <Button
                      type="submit" 
                      class="p-button-primary"
                      label="Add Popup"
                      icon="pi pi-plus-circle"
                      @click="addPopup"
                    />
                  </div>
                </div>
              </div>
            </div>
            <h5 class="mt-4 mb-4">
              <i class="pi pi-cog" ></i> Price List Items & Popups
              <b class="pull-right p-2 badge badge-danger" >{{navigatetor}}</b>
            </h5>
             <div class="table-responsive">
              <table class="table table-bordered table-striped pricelist-table table-hover">
                <thead>
                  <tr>
                    <th>SNO</th>
                    <th>Service</th>
                    <th>Department</th>
                    <th>Item Name</th>
                    <th>Item Price</th>
                    <th>Popup</th>
                    <th>D-Price $</th>
                    <th>Cond Price $</th>
                    <th>Item Status</th>
                    <th>Popup Status</th>
                  </tr>
                </thead>
                 <tbody v-for="(items, index) in itemList"  :key="items" >
                  <tr>
                    <td>{{index + 1}}</td>
                    <td><b>{{items.serviceName}}</b></td>
                    <td>{{items.departmentName}}</td>
                    <td>{{items.itemName}}</td>
                    <td>
                      <input
                        type="text"
                        step=".01"
                        class="price-list-box"
                         v-model="items.itemPrice"
                      />
                    </td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td class="text-center align-middle">
                      <div class="btn-group align-top">
                        <input
                          v-if="items.itemStatus == 'Active'"
                          style="width: 25px; height: 25px"
                          type="checkbox"
                          v-model="items.itemStatus"
                          checked
                          true-value="Active"
                          false-value="Inactive"
                        />
                        <input
                          v-if="items.itemStatus == 'Inactive'"
                          style="width: 25px; height: 25px"
                          type="checkbox"
                          v-model="items.itemStatus"
                          true-value="Active"
                          false-value="Inactive"
                        />
                      </div>
                    </td>
                    <td class="text-center align-middle"></td>
                  </tr>
                  <tr v-for="pops in items.itemPopups" :key="pops">
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td>{{pops.itemSpName}}</td>
                    <td>{{pops.itemSpPrice}}</td>
                    <td>
                      <input
                        type="number"
                        step=".01"
                        class="price-list-box"
                        v-model="pops.conditionPrice"
                      />
                    </td>
                    <td class="text-center align-middle"></td>
                    <td class="text-center align-middle">
                      <div class="btn-group align-top">
                        <input
                          v-if="pops.conditionStatus == 'Active'"
                          type="checkbox"
                          checked
                          style="width: 25px; height: 25px"
                          v-model="pops.conditionStatus"
                          true-value="Active"
                          false-value="Inactive"
                        />
                        <input
                          v-if="pops.conditionStatus == 'Inactive'"
                          type="checkbox"
                          style="width: 25px; height: 25px"
                          v-model="pops.conditionStatus"
                          true-value="Active"
                          false-value="Inactive"
                        />
                      </div>
                    </td>
                  </tr>
                </tbody>
                <tfoot v-if="itemList.length > 0">
                  <tr>
                    <td colspan="10">
                      <Button
                      type="submit"
                        label="Update Price List"
                        icon="pi pi-check"
                        class="p-button-primary pull-right"
                        @click="updatePricelist"  />
                    </td>
                  </tr>
                </tfoot>
              </table>
          </div>
          </div>
        </div><!-- .price-list-search-result-->

        <!-- .filter-price-list -->
        <div class="copy-price-list" v-if="viewTabs == 'copy-price-list'">
          <h5><i class="pi pi-clone"></i> Copy the following details</h5>
          <div class="p-fluid p-formgrid p-grid">
            <div class="p-field p-col-6">
              <Dropdown
                placeholder="Choose schedule to copy from"
                v-model="seletectedScheduleFrom"
                :options="scheduleList"
                optionLabel="scheduleName"
                :filter="true"
              />
            </div>
            <div class="p-field p-col-6">
              <Dropdown
                placeholder="Choose schedule to copy in"
                v-model="seletectedScheduleTo"
                :options="scheduleList"
                optionLabel="scheduleName"
                :filter="true"
              />
            </div>
            <div class="p-field p-col-12 mb-5">
              <TabView  @tab-change="clearObject()">
                <TabPanel header="All">
                  <div class="d-flex align-items-center flex-column my-4">
                    <div class="p-field-checkbox mb-auto">
                      <Checkbox
                        id="allItemsConditions"
                        v-model="optApply"
                        value="all"
                      />
                      <label id="allItemsConditions">COPY ALL ITEMS</label>
                    </div>
                    <div class="mb-auto">
                      <p>
                        By clicking on this it will copy to all items to price
                        list
                      </p>
                    </div>
                  </div>
                </TabPanel>
                <TabPanel header="Service">
                  <div class="p-fluid">
                    <div class="p-field">
                      <label for="serviceCopy">Service to copy</label>
                      <Dropdown
                        id="serviceCopy"
                        :options="serviceList"
                        v-model="optApply"
                        optionLabel="name"
                        :filter="true"
                      />
                    </div>
                  </div>
                </TabPanel>
                <TabPanel header="Dept">
                  <div class="p-fluid">
                    <div class="p-field">
                      <label for="departmentCopy">Department to copy</label>
                      <Dropdown
                        id="departmentCopy"
                        :options="departmentList"
                        v-model="optApply"
                        optionLabel="sector"
                        :filter="true"
                      />
                    </div>
                  </div>
                </TabPanel>
                <TabPanel header="Popus">
                  <div class="d-flex align-items-center flex-column my-4">
                    <div class="p-field-checkbox mb-auto">
                      <Checkbox
                        value="all_condition"
                        id="allConditions"
                        v-model="optApply"
                      />
                      <label for="allConditions"
                        >COPY ALL THE POPUP CONDITIONS</label
                      >
                    </div>
                    <div class="mb-auto">
                      <p class="text-danger">
                        Note : By clicking on this it will overwrite all
                        previous conditions exists in copying price list
                      </p>
                    </div>
                  </div>
                </TabPanel>
              </TabView>
              <div class="p-grid">
                <div class="p-col-12"></div>
              </div>
            </div>
            <div class="p-field p-col-12 text-center">
              <div class="p-d-inline-flex">
                <Button
                  type="submit"
                  class="p-button-primary"
                  label="Copy Price List"
                  icon="pi pi-clone"
                  @click="copyPriceList"
                />
              </div>
            </div>
          </div>
        </div>
        <!-- .copy-price-list -->
      </div>
    </div>
  </section>
  <Dialog
    v-model:visible="addItemDailog"
    :style="{ width: '50vw' }"
    :maximizable="true"
    position="top"
    class="p-fluid p-p-0"
  >
    <template #header>
      <h5 class="p-dialog-titlebar p-dialog-titlebar-icon">
        <i class="pi pi-plus-circle"></i> Add New Item In Price List
      </h5>
    </template>
    <div class="p-field">
      <div class="p-field">
        <label for="schedulesName">Schedules Name</label>
        <Dropdown
          id="schedulesName"
          v-model="seletectedSchedule"
          :options="scheduleList"
          optionLabel="scheduleName"
          :filter="true"
        />
      </div>
      <div class="p-field">
        <label for="services">Services</label>
        <Dropdown
          id="services"
          :options="serviceList"
          @change="fetchItems"
          v-model="seletectedService"
          optionLabel="name"
          :filter="true"
        />
      </div>
      <div class="p-field">
        <label for="departments">Departments</label>
        <Dropdown
          id="departments"
          :options="departmentList"
          @change="fetchItems"
          v-model="seletectedDepart"
          optionLabel="sector"
          :filter="true"
        />
      </div>
      <div class="p-field">
        <label for="items">Items</label>
        <Dropdown
          id="items"
          :options="itemListPopup"
          v-model="selecteditem"
          optionLabel="itemName"
          :filter="true"
        />
      </div>
      <div class="p-field">
        <label for="price">Price:</label>
        <InputNumber
          id="price"
          mode="currency"
          v-model="price"
          currency="USD"
          locale="en-US"
        />
      </div>
    </div>
    <template #footer>
      <Button
        type="submit"
        label="Save Item Price"
        icon="pi pi-check-circle"
        class="p-button-primary"
        @click="saveItem"
      />
    </template>
  </Dialog>
  <Dialog
    v-model:visible="itemPriceOption"
    :style="{ width: '50vw' }"
    :maximizable="true"
    position="top"
    class="p-fluid p-p-0"
  >
    <template #header>
      <h5 class="p-dialog-titlebar p-dialog-titlebar-icon">
        <i class="pi pi-cog"></i> Price List Options
      </h5>
    </template>
    <div class="p-field">
      <div class="p-field">
        <label for="schedulesName">Schedules Name</label>
        <Dropdown
          id="schedulesName"
          v-model="seletectedSchedule"
          :options="scheduleList"
          optionLabel="scheduleName"
          :filter="true"
        />
      </div>
      <div class="p-field">
        <label for="services">Services</label>
        <Dropdown
          id="services"
          :options="serviceList"
          v-model="seletectedService"
          optionLabel="name"
          :filter="true"
        />
      </div>
      <div class="p-field">
        <label for="departments">Departments</label>
        <Dropdown
          id="departments"
          :options="departmentList"
          v-model="seletectedDepart"
          optionLabel="sector"
          :filter="true"
        />
      </div>
      <div class="p-field">
        <label for="Percentage">Percentage</label>
        <Dropdown
          id="Percentage"
          :options="percentageList"
          v-model="selectedPrecentage"
          optionLabel="name"
          :filter="true"
        />
      </div>
      <div class="p-field">
        <label for="percentage_value">Percentage value</label>
        <InputText id="percentage_value" v-model="percentageValue" />
      </div>
    </div>
    <template #footer>
      <Button
        type="submit"
        label="Apply Option"
        icon="pi pi-check-circle"
        class="p-button-primary"
        @click="applyOption"
      />
    </template>
  </Dialog>
</template>
<script lang="ts">
import { Options, Vue } from "vue-class-component";
import Toaster from "../../helpers/Toaster";
import PriceListService from "../../service/PriceListService";
import { camelCase } from "lodash";
@Options({
  components: {},
})
export default class PriceList extends Vue {
  private viewTabs = "filter-price-list";
  private viewSearchResult = "";
  private home = { icon: "pi pi-home", to: "/" };
  private items = [
    { label: "Initialization", to: "initialization" },
    { label: " Price List", to: "/store/price-list" },
  ];

  private toast;
  private priceTxn;
  private submitted = false;
  private allItemsCheckbox = "";
  private allConditionCheckbox = "";
  private addItemDailog = false;
  private itemPriceOption = false;
  private serviceList = [];
  private departmentList = [];
  private scheduleList = [];
  private itemList = [];
  private itemListPopup = [];
  private popupList = [];
  private optApply = ['all'];
  private price = 0;
  private navigatetor = "";

  private percentageList = [
    {
      name: "Percentage Plus",
      value: "percentage_plus",
    },
    {
      name: "Percentage Minus",
      value: "percentage_minus",
    },
  ];

  private seletectedSchedule = {
    id: "",
    scheduleName: "",
  };

  private seletectedScheduleFrom = {
    id: "",
    scheduleName: "",
  };

  private seletectedScheduleTo = {
    id: "",
    scheduleName: "",
  };

  private seletectedService = {
    id: "",
    name: "",
  };

  private seletectedDepart = {
    id: "",
    sector: "",
  };

  private selecteditem = {
    id: "",
    itemName: "",
  };

  private selectedPrecentage = {
    name: "",
    value: "",
  };

  private seletectedPopUp = {
    itemSpId: "",
  };

  // private priceList = {
  //   itemPrice: [0],
  //   condPrice: "",
  //   department: "",
  //   itemStatus: [''],
  //   popupStatus:"Active"
  // };

  private percentageValue = 0;

  searchPriceList() {
    this.viewSearchResult = 'price-list-search-result';
    this.priceTxn
      .searchPriceList(
        this.seletectedService.id,
        this.seletectedDepart.id,
        this.seletectedSchedule.id,
      )
      .then((res) => {
        const d = this.camelizeKeys(res);
        this.navigatetor = d.schName +' > '+ d.serviceName +' > '+ d.departmentName;
        this.popupList = d.popus
        this.itemList = d.itemList;
      });
  }

  created() {
    this.priceTxn = new PriceListService();
    this.toast = new Toaster();
  }

  mounted() {
    this.priceTxn.getPriceItemList().then((data) => {
      const d = this.camelizeKeys(data);
      this.serviceList = d.serviceList;
      this.departmentList = d.departmentList;
      this.scheduleList = d.scheduleList;
    });
  }

  openItemDialog() {
    this.addItemDailog = true;

    //LOAD THE ITEMS LOADED BY DEFAULT
    this.fetchItems();
  }

  //SWITCH TABS
  activePanel(value) {
    this.viewTabs = value;
  }

  camelizeKeys = (obj) => {
    if (Array.isArray(obj)) {
      return obj.map((v) => this.camelizeKeys(v));
    } else if (obj !== null && obj.constructor === Object) {
      return Object.keys(obj).reduce(
        (result, key) => ({
          ...result,
          [camelCase(key)]: this.camelizeKeys(obj[key]),
        }),
        {}
      );
    }
    return obj;
  };

  fetchItems() {
    if (this.seletectedService.id != "" && this.seletectedDepart.id != "") {
      this.priceTxn
        .getItemList(this.seletectedService.id, this.seletectedDepart.id)
        .then((data) => {
          const item = this.camelizeKeys(data);
          this.itemListPopup = item;
        });
    }
  }

  saveItem() {
    this.priceTxn
      .saveItemPrice(
        this.seletectedService.id,
        this.seletectedDepart.id,
        this.seletectedSchedule.id,
        this.selecteditem.id,
        this.price
      )
      .then((res) => {
        this.toast.handleResponse(res);
        this.addItemDailog = false;

        //REFRESH THE PRICE LIST
        this.searchPriceList();
      });
  }

  openOptionSettings() {
    this.itemPriceOption = true;
  }

  applyOption() {
    if (this.selectedPrecentage.value == "" || this.percentageValue == 0) {
      this.toast.showWarning(
        "Please enter the value greater then zero or choose the apply percentage option"
      );
    } else {
      this.priceTxn
        .applyOption(
          this.seletectedService.id,
          this.seletectedDepart.id,
          this.seletectedSchedule.id,
          this.selectedPrecentage.value,
          this.percentageValue
        )
        .then((res) => {
          this.toast.handleResponse(res);
          this.itemPriceOption = false;
        });
    }
  }

  formatAmount(value) {
    value = Number(value);

    let balance = 0;

    if (value != "") {
      balance = value.toFixed(2);
    }
    return balance;
  }

  addPopup()
  {
    this.priceTxn
      .addPopup(
       this.seletectedSchedule.id,
       this.seletectedPopUp.itemSpId
      )
      .then((res) => {
        this.toast.handleResponse(res);
        this.searchPriceList();
    });
  }

  copyPriceList() {
    this.priceTxn
    .copyPriceList(
      this.seletectedScheduleFrom.id,
      this.seletectedScheduleTo.id,
      this.optApply
    )
    .then((res) => {
      this.toast.handleResponse(res);
    });
  }

  //CLEAR DATA WHEN TOGGLING TABS IN DIALOG BOX
  clearObject() {
    this.optApply = [];
  }

  updatePricelist()
  {
    this.priceTxn
      .updatePriceList(
        this.seletectedSchedule.id,
        this.itemList
      )
      .then((res) => {
        this.toast.handleResponse(res);
        if(res.alert == 'info')
        {
          this.searchPriceList();
        }
      });
  }
}
</script>

<style scoped>
.pricelist-table th , td
{
  padding: 2px;
  margin:0;
}

.price-list-box
{
  width:80px;
}
</style>